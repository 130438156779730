import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SelectBoxText = (
    {
        name,
        subtitle,
        onSelect,
        color
    }
) => {
    const theme = useContext(ThemingContext);

    return (
        <TouchableOpacity onPress={() => onSelect(name)} style={{
            padding: 20,
            backgroundColor:color,
            borderRadius: 5,
            margin: 10,
            flex: 1,
            overflow: "hidden"
        }}>

            <Text style={{
                fontFamily: theme.Font,
                textAlign: "center",
                fontSize: 37,
                color: "white",
                fontWeight: "bold"
            }}>{name}</Text>

            <Text style={{
                fontFamily: theme.Font,
                marginTop: 5,
                textAlign: "center",
                fontSize: 16,
                color: "white",
                fontWeight: "bold"
            }}>{subtitle}</Text>

        </TouchableOpacity>
    )
}

export default SelectBoxText;
