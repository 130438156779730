import React, {useState} from 'react';
import { StyleSheet, Text, View } from 'react-native';

export default function Step({children}) {

    return (
        <View animation="fadeIn" duration={100} style={{
            flex: 1,
            flexDirection: "row"
        }}>
            {children}
        </View>
    );
}
