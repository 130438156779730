import React, {useContext, useState} from 'react';
import {Image, View,} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Sidebar from "../Common/SideBar";
import Content from "../Common/Content";
import Title from "../Common/Title";
import Step from "../Common/Step";
import {StepContext} from "../Common/Contexts";
import Slider from "../Common/Slider";
import SelectBoxText from "../Common/SelectBoxText";
import CenterSidebar from "../Common/CenterSidebar";
import SidebarLogo from "../Common/SidebarLogo";

const StepTwo = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, PaidBackOver: name});
        fnSetStep(3);
    };

    return (
        <Step>

            <Sidebar>
                <SidebarLogo imageSrc={require("./assets/SonderLogo.png")}/>

                <CenterSidebar>
                    <Image source={require("../SonderCTA/assets/StepThreeGoogle.png")} resizeMode="contain" style={{
                        width: 100,
                        height: 200,
                        alignSelf: "center"
                    }}/>
                </CenterSidebar>
            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    How much are you wanting to invest?
                </Title>


                <Slider
                    value={details.HowMuchToInvest}
                    onChangeText={(value) => setDetails({...details, HowMuchToInvest: value})}/>


                <Title>Paid back over?</Title>

                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBoxText name="6"
                                   subtitle="months"
                                   color="#2C2B5D"
                                   onSelect={onSelect}/>

                    <SelectBoxText name="12"
                                   subtitle="months"
                                   color="#FEC450"
                                   onSelect={onSelect}/>

                </View>

                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBoxText name="18"
                                   subtitle="months"
                                   color="#E40046"
                                   onSelect={onSelect}/>

                    <SelectBoxText name="24"
                                   subtitle="months"
                                   color="#138BE8"
                                   onSelect={onSelect}/>

                </View>


            </Content>

        </Step>
    );
};

export default StepTwo;
