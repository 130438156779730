import React, {useState, createRef, useEffect, useCallback} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {StepContext, ThemingContext} from "./Contexts";
import ReactGA, {set} from 'react-ga';
import {parse} from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function CTA({id, steps, theme, object, doSubmission, gaID = "UA-145507528-1", recaptchaSiteKey }) {
    const [details, setDetails] = useState(object);
    const [loading, setLoading] = useState(false);

    const QS = parse(location.search);
    const recaptchaRef = createRef();

    const [step, setStep] = useState(QS && QS.step != null ? parseInt(QS.step) : 1);

    const CurrentComponent = steps[step - 1];

    const complete = async () => {
        setLoading(true);


        try {

            let recaptchaToken = "";

            if (recaptchaSiteKey) {
                recaptchaToken = await recaptchaRef.current.executeAsync();
            }

            const ok = await doSubmission(details, recaptchaToken);

            if (!ok) {
                alert("Please try again");

                return;
            }

            ReactGA.event({
                category: 'CTA Step',
                action: `Complete`
            });

            setStep(step + 1);
            setLoading(false);


        } catch (e) {

            console.log(e);

            const ok = await doSubmission(details, "");

            if (!ok) {
                alert("Please try again");

                return;
            }

            ReactGA.event({
                category: 'CTA Step',
                action: `Complete`
            });

            setStep(step + 1);
            setLoading(false);

        }
    };

    const nextStep = (number) => {
        ReactGA.event({
            category: 'CTA Step',
            action: `Step ${number}`
        });

        setStep(number);
    }

    const stepContext = {
        Total: steps.length,
        Current: step,
        fnSetStep: useCallback(nextStep),
        fnComplete: useCallback(complete)
    };

    useEffect(() => {
        ReactGA.initialize(gaID);

        ReactGA.ga('send', 'pageview', '/cta/' + id);
    }, []);

    return (
        <ThemingContext.Provider value={theme}>
            <StepContext.Provider value={stepContext}>
                <View style={{
                    flex: 1,
                    backgroundColor: "white"
                }}>
                    {loading ? <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}><Loader
                        type="ThreeDots"
                        color={theme.Button}
                        height={100}
                        width={100}
                    /></View> : <CurrentComponent details={details} setDetails={setDetails}/>}

                    <TouchableOpacity style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        paddingRight: 20,
                        padding: 10
                    }} onPress={() => window.closeCTA()}>
                        <Text style={{
                            fontSize: 20,
                            color: "grey"
                        }}>x</Text>
                    </TouchableOpacity>

                    {recaptchaSiteKey && <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={recaptchaSiteKey} />}
                </View>
            </StepContext.Provider>
        </ThemingContext.Provider>
    );
}
