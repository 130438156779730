import React, { useState, useEffect } from 'react';
import { Dimensions} from "react-native";

function useIsMobile(props) {
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;

    const isMobile = windowWidth <= 950;

    return {
        maxCTAWidth: windowWidth >= 950 ? 950 : windowWidth,
        maxCTAHeight: windowHeight >= 650 ? 650 : windowHeight,
        marginTop: isMobile ? 10 : (windowHeight >= 750 ? 100 : 0),
        isMobile: isMobile
    };
}

export default useIsMobile;
