import React from 'react';
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import CTA from "../Common/CTA";

import './assets/Fonts.css'

export default function SonderCTA() {
    return (
        <CTA id="sonder"
             theme={{
                 Font: "Montserrat",
                 Background: {
                     Gradient: "linear-gradient(#E40046, #9D0030)"
                 },
                 SelectedOption: "#0197F6",
                 ProgressBar: "#2C2B5D",
                 Button: "#E40146"
             }}
             doSubmission={async (data, recaptchaToken) => {
                 const message = 'A new CTA submissions \n\n\n' +
                     `What services are you interested in?: ${data.InterestedServices} \n\n` +
                     `Which best describes your marketing?: ${data.BestDescribesMarketing} \n\n` +
                     `Are you a new or existing business?: ${data.NewBusiness} \n\n` +
                     `Business name: ${data.BusinessName} \n\n` +
                     `Website: ${data.BusinessWebSite} \n\n` +
                     `Social Media: ${data.SocialMediaAccount} \n\n` +
                     `Message: ${data.Message} \n\n`;

                 let formData = new FormData();
                 formData.append('post_id', '3106');
                 formData.append('form_id', '5993955');
                 formData.append('referer_title', 'Sonder CTA');
                 formData.append('queried_id', '3106');
                 formData.append('g-recaptcha-response', recaptchaToken);
                 formData.append('action', 'elementor_pro_forms_send_form');
                 formData.append('referrer', 'https://sonder.com.au/contact/');
                 formData.append('form_fields[name2]', data.MyName);
                 formData.append('form_fields[email]', data.MyEmail);
                 formData.append('form_fields[field_35c22b6]', data.MyPhoneNumber);
                 formData.append('form_fields[field_53ce031]', data.BusinessName);
                 formData.append('form_fields[message]', message);

                 try {
                     let response = await fetch("/wp-admin/admin-ajax.php", {
                         body: formData,
                         method: "post"
                     });

                     if (response && response.ok) {
                         return true;
                     }

                     return false;

                 } catch (e) {
                     console.log("Failed to submit", e);

                     return false;
                 }
             }}
             object={{
                 InterestedServices: null,
                 BestDescribesMarketing: null,
                 NewBusiness: null,
                 BusinessName: null,
                 BusinessWebSite: null,
                 SocialMediaAccount: null,
                 MyName: null,
                 MyPhoneNumber: null,
                 MyEmail: null,
                 Message: null
             }}
             steps={[
                 StepOne,
                 StepTwo,
                 StepThree,
                 StepFour,
                 StepFive
             ]}/>
    );
}
