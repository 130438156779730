import React, {useContext, useState} from 'react';
import {Image} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import OptionSet from "../Common/OptionSet";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";


const StepThree = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["VehicleRegistration", "VehicleModel", "VehicleMake", "OdometerReading"], details)) {
            return;
        }

        fnSetStep(4);
    };

    return (
        <Step>

            <Sidebar>

                <CenterSidebar>
                    <Image source={require("./assets/step3.png")} resizeMode="contain" style={{
                        width: 100,
                        height: 200,
                        alignSelf: "center"
                    }}/>
                </CenterSidebar>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    We just need a few details about your car
                </Title>


                <TextInput value={details.VehicleRegistration}
                           label="Vehicle registration"
                           onChangeText={(value) => setDetails({...details, VehicleRegistration: value})}/>


                <TextInput value={details.VehicleModel}
                           label="Vehicle Model"
                           onChangeText={(value) => setDetails({...details, VehicleModel: value})}/>


                <TextInput value={details.VehicleMake}
                           label="Vehicle Make"
                           onChangeText={(value) => setDetails({...details, VehicleMake: value})}/>


                <TextInput value={details.OdometerReading}
                           label="Odometer reading"
                           onChangeText={(value) => setDetails({...details, OdometerReading: value})}/>

                <TextInput value={details.Notes}
                           label="Anything else we should know? (optional)"
                           onChangeText={(value) => setDetails({...details, Notes: value})}/>


                <Button title={"Continue"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepThree;
