import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const OptionSet = ({ options, label, onChangeText}) => {
    const [selected, setSelected] = useState(null);
    const theme = useContext(ThemingContext);

    const selectedOption = (name) => {
        setSelected(name);

        onChangeText(name);
    }

    return (
        <View style={{
            marginBottom: 20
        }}>
            <Text style={{
                fontFamily: theme.Font,
                fontSize: 14,
                color: "#333333",
                fontWeight: "bold",
                marginBottom: 10
            }}>{label}</Text>


            <View style={{
                flexDirection: "row"
            }}>

                {options.map(option => <TouchableOpacity onPress={() => selectedOption(option)} key={option} style={{
                    backgroundColor: selected === option ? theme.SelectedOption : "white",
                    paddingVertical: 13,
                    paddingHorizontal: 20,
                    marginRight: 20,
                    borderRadius: 5,
                    shadowColor: "rgba(0,0,0,0.31)",
                    shadowOffset: {
                        width: 0,
                        height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                }}>
                    <Text style={{
                        fontFamily: theme.Font,
                        fontSize: 14,
                        color: selected === option ? "white" : "#333333",
                        fontWeight: "bold",
                        textAlign: "center"
                    }}>{option}</Text>
                </TouchableOpacity>)}
            </View>

        </View>
    );
};

export default OptionSet;
