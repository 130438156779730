import React from 'react';
import StepOne from "./StepOne";
import CTA from "../Common/CTA";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";

export default function LeMansCTA() {
    return (
        <CTA id="lemans"
             preview={true}
             theme={{
                 Font: "Montserrat",
                 Background: {
                     Color: "rgb(237, 27, 35)"
                 },
                 SelectedOption: "#0197F6",
                 ProgressBar: "rgb(237, 27, 35)",
                 Button: "rgb(237, 27, 35)"
             }}
             gaID="UA-145523803-1"
             recaptchaSiteKey="6LerrjobAAAAAC1UhgxPKICz0jprw7YdN7nD-hlV"
             doSubmission={async (data, recaptchaToken) => {

                 let formData = new FormData();
                 formData.append('post_id', '146');
                 formData.append('form_id', '449b0a2');
                 formData.append('referer_title', 'LeMans CTA');
                 formData.append('queried_id', '146');
                 formData.append('g-recaptcha-response', recaptchaToken);
                 formData.append('action', 'elementor_pro_forms_send_form');
                 formData.append('referrer', 'https://lemansmotors.com.au/');

                 formData.append('form_fields[firstname]', data.MyName);
                 formData.append('form_fields[lastname]', data.MyName);
                 formData.append('form_fields[phone]', data.MyPhoneNumber);
                 formData.append('form_fields[email]', data.MyEmail);
                 formData.append('form_fields[locationemail]', data.Location);
                 formData.append('form_fields[servicerequired]', data.BookInFor);
                 formData.append('form_fields[date]', data.PreferredDate);
                 formData.append('form_fields[time]', data.PreferredDate);
                 formData.append('form_fields[make]', data.VehicleMake);
                 formData.append('form_fields[model]', data.VehicleModel);
                 formData.append('form_fields[rego]', data.VehicleRegistration);
                 formData.append('form_fields[currentkm]', data.OdometerReading);
                 formData.append('form_fields[comments]', 'Phone: ' + data.MyPhoneNumber + ' - ' + data.Notes);

                 try {
                     let response = await fetch("/wp-admin/admin-ajax.php", {
                         body: formData,
                         method: "post"
                     });

                     let json = await response.json();

                     if (!json.success) {
                         return false;
                     }

                     return true;

                 } catch (e) {
                     console.log("Failed to submit", e);

                     return false;
                 }
                 return true;
             }}
             object={{}}
             steps={[
                 StepOne,
                 StepTwo,
                 StepThree,
                 StepFour,
                 StepFive
             ]}/>
    );
}
