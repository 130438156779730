import React, {useContext, useState} from 'react';
import {Image} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import OptionSet from "../Common/OptionSet";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";


const StepThree = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["NewBusiness", "BusinessName"], details)) {
            return;
        }

        fnSetStep(4);
    };

    return (
        <Step>

            <Sidebar>

                <CenterSidebar>
                    <Image source={require("./assets/StepThreeGoogle.png")} resizeMode="contain" style={{
                        width: 100,
                        height: 200,
                        alignSelf: "center"
                    }}/>
                </CenterSidebar>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    We just need a few details about your business
                </Title>


                <OptionSet
                    options={["New", "Existing"]}
                    label="Are you a new or existing business?"
                    onChangeText={(value) => setDetails({...details, NewBusiness: value})}/>

                <TextInput value={details.BusinessName}
                           label="Business name"
                           onChangeText={(value) => setDetails({...details, BusinessName: value})}/>

                <TextInput value={details.BusinessWebSite}
                           label="Website URL (optional)"
                           onChangeText={(value) => setDetails({...details, BusinessWebSite: value})}/>

                <TextInput value={details.SocialMediaAccount}
                           label="Social media account/s (please link)"
                           onChangeText={(value) => setDetails({...details, SocialMediaAccount: value})}/>


                <Button title={"Continue"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepThree;
