import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import SelectRow from "../Common/SelectRow";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";
import SideBarTitle from "../Common/SideBarTitle";
import * as Animatable from "react-native-animatable";

const StepOne = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, Location: name});
        fnSetStep(2);
    };

    return (
        <Step>
            <Sidebar center={true}>

                <CenterSidebar>
                    <SideBarTitle>
                        #1 Rated Brisbane Mechanic
                    </SideBarTitle>

                    <SideBarText>
                        Our crew of car experts have been building our reputation in Brisbane for more than 47 years
                        now. With a team of experienced mechanics using modern servicing equipment and a commitment to
                        delivering top notch workmanship — we offer a premium car service.
                    </SideBarText>


                </CenterSidebar>

                <Animatable.View animation="fadeIn" style={{
                    height: 210
                }}>

                    <Image source={require("./assets/step1.png")} resizeMode="contain" style={{
                        maxWidth: "100%",
                        height: "100%"
                    }}/>
                </Animatable.View>
            </Sidebar>


            <Content>
                <ProgressBar/>

                <Title>
                    Please select your preferred workshop
                </Title>


                <SelectRow name="LeMans Motors Woolloongabba" value="woolloongabba@lemansmotors.com.au" onSelect={onSelect}/>

                <SelectRow name="LeMans Motors West End" value="westend@lemansmotors.com.au" onSelect={onSelect}/>

                <SelectRow name="LeMans Motors Milton" value="milton@lemansmotors.com.au" onSelect={onSelect}/>

                <SelectRow name="LeMans Motors Bulimba" value="bulimba@lemansmotors.com.au" onSelect={onSelect}/>

                <SelectRow name="LeMans Motors Newstead" value="newstead@lemansmotors.com.au" onSelect={onSelect}/>


            </Content>
        </Step>
    );
};

export default StepOne;
