import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';

const Center = (
    {
        children
    }
) => {

    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1
        }}>
            {children}
        </View>
    )
}

export default Center;
