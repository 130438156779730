import React, {useContext, useState} from 'react';
import {Image, View} from "react-native";

export default function SidebarLogo({ imageSrc} ) {
    return (
        <Image source={imageSrc} resizeMode="contain" style={{
            width: 60,
            height: 60,
            marginBottom: 30
        }}/>

    )
}
