import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const Button = (
    {
        onPress,
        title
    }
) => {
    const theme = useContext(ThemingContext);

    return (
        <TouchableOpacity onPress={onPress} style={{
            padding: 13,
            backgroundColor: theme.Button,
            borderRadius: 5,
            flexDirection: "row",
            alignItems: "center",
            overflow: "hidden",
            width: 150,
            shadowColor: "rgba(0,0,0,0.31)",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

        }}>
            <Text style={{
                fontFamily: theme.Font,
                fontSize: 16,
                color: "white",
                marginRight: 10,
                fontWeight: "bold",
            }}>
                {title}
            </Text>
            <Image source={require("./ContinueArrow.png")} resizeMode="contain" style={{
                width: 15,
                height: 15
            }}/>

        </TouchableOpacity>
    )
}

export default Button;
