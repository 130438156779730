export const ValidateMe = (requiredFields, object) => {
    for (let i = 0; i < requiredFields.length; i++) {
        let field = requiredFields[i];

        if (object[field] == null || object[field].length == 0) {
            alert("Please complete the required field");

            return false;
        }

        if (field === "MyEmail") {
            if (object[field].indexOf('@') < 0) {
                alert("Please enter a valid eMail address");

                return false;
            }

            if (object[field].indexOf('.') < 0) {
                alert("Please enter a valid eMail address");

                return false;
            }
        }

        if (field === "MyPhoneNumber") {
            if (object[field].length < 8) {
                alert("Please enter a valid phone number");

                return false;
            }
        }
    }

    return true;
}
