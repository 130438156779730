import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SideBarSmallText = ({children}) => {
    const theme = useContext(ThemingContext);

    return (
        <Text style={{
            fontFamily: theme.Font,
            fontSize: 12,
            color: "white"
        }}>
            {children}
        </Text>
    )
};

export default SideBarSmallText;
