import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SideBarText = ({children, textColor = "white"}) => {
    const { Font } = useContext(ThemingContext);

    return (
        <Text style={{
            fontFamily:Font,
            fontSize: 16,
            color: textColor
        }}>
            {children}
        </Text>
    )
};

export default SideBarText;
