import React, {useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import Content from "../Common/Content";
import Step from "../Common/Step";
import CompleteTitle from "../Common/CompleteTitle";
import CompleteText from "../Common/CompleteText";
import useIsMobile from "../Common/useIsMobile";

const StepFive = ({details, setStep, setDetails}) => {
    const {isMobile} = useIsMobile();

    return (
        <Step>
            <Content center={!isMobile}>


                <CompleteTitle center={isMobile}>Thank you! </CompleteTitle>

                <CompleteText center={isMobile}>One of our friendly team will be in touch with you soon to confirm your
                    booking.</CompleteText>

                <TouchableOpacity style={{flex: 1}}
                                  onPress={() => document.location = "https://lemansmotors.com.au/services/car-service/"}>

                    <Image source={isMobile ? require('./assets/step5mobile.png') : require("./assets/step5.png")}
                           resizeMode={"contain"} style={{
                        flex: 1
                    }}/>
                </TouchableOpacity>


            </Content>

        </Step>
    );
};

export default StepFive;
