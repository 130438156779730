import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import SideBarSmallText from "../Common/SideBarSmallText";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import Quote from "../Common/Quote";
import CenterSidebar from "../Common/CenterSidebar";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import SideBarTitle from "../Common/SideBarTitle";

const StepFour = ({details, setDetails}) => {
    const {fnComplete} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["MyName", "MyPhoneNumber", "MyEmail", "PreferredDate"], details)) {
            return;
        }

        fnComplete();
    };

    return (
        <Step>

            <Sidebar>
                <CenterSidebar>
                    <SideBarTitle>
                        RACQ Approved Repairer
                    </SideBarTitle>

                    <SideBarText>
                        We give our customers peace of mind in the knowledge that all of our workshops are part of the
                        RACQ Approved Repairer network.
                    </SideBarText>
                </CenterSidebar>

                <BottomImageSideBar imageSrc={require("./assets/step4.png")}/>
            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    Please share your personal details so that we can get in touch
                </Title>


                <TextInput value={details.MyName}
                           label="Your name"
                           onChangeText={(value) => setDetails({...details, MyName: value})}/>

                <TextInput value={details.MyPhoneNumber}
                           label="Your phone number"
                           onChangeText={(value) => setDetails({...details, MyPhoneNumber: value})}/>

                <TextInput value={details.MyEmail}
                           label="Your eMail"
                           onChangeText={(value) => setDetails({...details, MyEmail: value})}/>


                <TextInput value={details.PreferredDate}
                           label="Your Preferred Date"
                           type={"date"}
                           onChangeText={(value) => setDetails({...details, PreferredDate: value})}/>


                <Button title={"Submit"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepFour;
