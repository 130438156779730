import React, {useContext, useState} from 'react';
import SideBarText from "./SideBarText";
import SideBarSmallText from "./SideBarSmallText";
import {Image, Text, View} from "react-native";
import {ThemingContext} from "./Contexts";

export default function Quote({quote, logo, name, title}) {
    const { Font } = useContext(ThemingContext);

    return (
        <View>
            <SideBarText>
                <Text style={{
                    fontFamily:Font,
                    fontSize: 22,
                    color: "white"
                }}>“</Text>
                {quote}
                <Text style={{
                    fontFamily:Font,
                    fontSize: 22,
                    color: "white"
                }}>”</Text>
            </SideBarText>

            <View style={{
                alignItems: "center",
                marginTop: 30,
                flexDirection: "row"
            }}>
                <Image source={logo} resizeMode="contain" style={{
                    width: 50,
                    height: 50,
                    marginRight: 20
                }}/>

                <SideBarSmallText>
                    {name}{'\n'}
                    {title}
                </SideBarSmallText>
            </View>
        </View>
    )
}
