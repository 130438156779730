import React, {useContext, useState} from 'react';
import {Slider as NativeSlider} from "@mui/material";
import {ThemingContext} from "./Contexts";
import {Text, View} from "react-native";

const Slider = ({label, value, onChangeText}) => {
    const {Font} = useContext(ThemingContext);

    const valueLabelFormat = (val) => `$${val},000`;

    return (
        <View style={{
            marginBottom: 20,
            paddingTop: 20
        }}>
            {label &&
            <Text style={{
                fontFamily: Font,
                fontSize: 14,
                color: "#333333",
                fontWeight: "bold",
                marginBottom: 10
            }}>{label}</Text>}

            <NativeSlider
                value={parseInt(value)}
                min={5}
                step={1}
                max={30}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={(e, newValue) => onChangeText(newValue.toString())}
                valueLabelDisplay="on"
                aria-labelledby="non-linear-slider"
            />
        </View>
    );
};

export default Slider;
