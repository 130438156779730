import React, {useContext} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';

const CenterSidebar = ({ children }) => {

    return (
        <View style={{
            flex: 1,
            justifyContent: "center"
        }}>
            {children}
        </View>
    );
};

export default CenterSidebar;
