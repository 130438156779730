import React from 'react';
import StepOne from "./StepOne";
import CTA from "../Common/CTA";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";


export default function SimpleSkipsCTA() {
    return (
        <CTA id="simpleskips"
             theme={{
                 Font: "Montserrat",
                 Background: {
                     Gradient: "linear-gradient(#E40046, #9D0030)"
                 },
                 SelectedOption: "#0197F6",
                 ProgressBar: "#2C2B5D",
                 Button: "#E40146"
             }}
             doSubmission={async (data, recaptchaToken) => {

                 let formData = new FormData();
                 formData.append('post_id', '187');
                 formData.append('form_id', 'dc30942');
                 formData.append('referer_title', 'CTA');
                 formData.append('queried_id', '187');
                 formData.append('action', 'elementor_pro_forms_send_form');
                 formData.append('referrer', 'https://simpleskips.com.au');

                 formData.append('form_fields[name]', data.Name);
                 formData.append('form_fields[email]', data.Email);
                 formData.append('form_fields[field_4370eb9]', data.BestDescribesYou);
                 formData.append('form_fields[field_0dbe830]', data.Size);
                 formData.append('form_fields[field_7d8a0d9]', data.ContactName);
                 formData.append('form_fields[field_d7267b9]', data.Address);
                 formData.append('form_fields[field_b253fb7]', data.Suburb);
                 formData.append('form_fields[field_f93545d]', data.Postcode);
                 formData.append('form_fields[field_71d7efc]', data.PhoneNumber);
                 formData.append('form_fields[field_cc8f91b]', data.DropOffDate);
                 formData.append('form_fields[field_7cfdc5c]', data.PickUpDate);

                 try {
                     let response = await fetch("https://simpleskips.au/wp-admin/admin-ajax.php", {
                         body: formData,
                         method: "post"
                     });

                     if (response && response.ok) {

                         const stripeLinks = {
                             "3M Cubed": "https://buy.stripe.com/4gweYt62d6m1eMo6oo",
                             "4M Cubed": "https://buy.stripe.com/9AQg2xeyJ7q5cEgbIJ",
                             "6M Cubed": "https://buy.stripe.com/dR63fL62d9yddIkdQS",
                             "8M Cubed": "https://buy.stripe.com/dR63fL62d9yddIkdQS",
                             "12M Cubed": "https://buy.stripe.com/4gw6rXaiteSxcEg7sx",
                             "12M Hook Bin": "https://buy.stripe.com/4gw3fLbmx25LgUweUY",
                             "15M Hook Bin": "https://buy.stripe.com/eVa17DeyJfWBgUwdQW"
                         }

                         const link = stripeLinks[data.Size];

                         document.location = link;

                         return true;
                     }

                     return false;

                 } catch (e) {
                     console.log("Failed to submit", e);

                     return false;
                 }
             }}
             object={{
                 BestDescribesYou: null,
                 TypeOfWaste: null,
                 Size:null,
                 Name: null,
                 Email: null,
                 ContactName: null,
                 Address: null,
                 Suburb: null,
                 Postcode: null,
                 PhoneNumber: null,
                 DropOffDate: null,
                 PickUpDate: null
             }}
             steps={[
                 StepOne,
                 StepTwo,
                 StepThree,
                 StepFour,
                 StepFive
             ]}/>
    );
}
