import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SelectRow = (
    {
        name,
        onSelect,
        value = null
    }
) => {
    const theme = useContext(ThemingContext);

    return (
        <TouchableOpacity onPress={() => onSelect(value != null ? value : name)} style={{
            padding: 20,
            backgroundColor: "white",
            borderRadius: 5,
            marginBottom: 15,
            overflow: "hidden"
        }}>

            <Text style={{
                fontFamily: theme.Font,
                textAlign: "center",
                fontSize: 16,
                color: "#333333",
                fontWeight: "bold"
            }}>{name}</Text>
        </TouchableOpacity>
    )
}

export default SelectRow;
