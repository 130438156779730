import React, {useState} from 'react';
 import Content from "../Common/Content";
import Step from "../Common/Step";
import CompleteTitle from "../Common/CompleteTitle";
import CompleteText from "../Common/CompleteText";
import useIsMobile from "../Common/useIsMobile";

const StepFive = ({details, setStep, setDetails}) => {
    const {isMobile} = useIsMobile();

    return (
        <Step>
            <Content>


                <CompleteTitle center={isMobile}>Thank you! </CompleteTitle>

                <CompleteText center={isMobile}>Sit tight. One of our friendly team will be in touch
                    soon.</CompleteText>




            </Content>

        </Step>
    );
};

export default StepFive;
