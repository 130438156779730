import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const Title = ({children}) => {
    const { Font } = useContext(ThemingContext);

    return (
        <Text style={{
            fontFamily: Font,
            fontSize: 20,
            color: "#333333",
            marginVertical: 30,
            fontWeight: "bold",
            textAlign: "left"
        }}>{children}</Text>
    );
};

export default Title;
