import React, {useContext, useState} from 'react';
import {Image, View} from "react-native";
import * as Animatable from 'react-native-animatable';

export default function BottomImageSideBar({ imageSrc} ) {
    return (
        <Animatable.View animation="fadeIn" style={{
            height: 250
        }}>

            <Image source={imageSrc} resizeMode="contain" style={{
                maxWidth: "100%",
                height: "100%"
            }}/>
        </Animatable.View>
    )
}
