import React, {useContext} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import useIsMobile from "./useIsMobile";
import {ThemingContext} from "./Contexts";

const Sidebar = ({center, children, style = {}}) => {
    const { Background } = useContext(ThemingContext);

    let staticStyle = {
        flex: 1,
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        justifyContent: center ? "center" : null,
        backgroundImage: Background.Gradient != null ? Background.Gradient : null
    };

    if (style && style.backgroundColor) {

    } else {
        staticStyle.backgroundColor = Background.Color;
    }

    const {isMobile} = useIsMobile();

    if (isMobile) {
        return null;
    }

    return (
        <View style={{...style, ...staticStyle}}>
            {children}
        </View>
    );
};

export default Sidebar;
