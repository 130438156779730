import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import Sidebar from "../Common/SideBar";
import Content from "../Common/Content";
import Step from "../Common/Step";
import CenterSidebar from "../Common/CenterSidebar";
import SidebarLogo from "../Common/SidebarLogo";
import Quote from "../Common/Quote";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import CompleteText from "../Common/CompleteText";
import CompleteTitle from "../Common/CompleteTitle";
import Center from "../Common/Center";

const StepFive = ({details, setDetails}) => {

    return (
        <Step>

            <Sidebar>
                <SidebarLogo imageSrc={require("./assets/SonderLogo.png")}/>

                <CenterSidebar>

                    <Quote title={'Owner, Vendito Consulting'}
                           quote={'We’ve used sonder to build 3 websites for our businesses, every time we engage Sonder we are always so happy. They bring our ideas to life and genuinely have a passion for making beautiful websites and SEO services.'}
                           logo={require("./assets/StepFoveLogo.png")}
                           name={'Blake Thompson'}/>

                </CenterSidebar>

                <BottomImageSideBar imageSrc={require("./assets/StepFivePerson.png")}/>

            </Sidebar>

            <Content>

                <Center>


                    <CompleteTitle>
                        Thank you!
                    </CompleteTitle>

                    <CompleteText center={true}>
                        Sit tight. One of our friendly team will be in touch soon.
                    </CompleteText>


                    <TouchableOpacity onPress={() => document.location = "https://sonder.com.au/about/"}>
                        <Image source={require('./assets/MeetTeam.png')} resizeMode="contain" style={{
                            width: 300,
                            height: 300,
                            marginTop: 40,
                            alignSelf: "center"
                        }}/>
                    </TouchableOpacity>

                </Center>

            </Content>

        </Step>
    );
};

export default StepFive;
