import React, {useContext, useState} from 'react';
import {Image, View,} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Sidebar from "../Common/SideBar";
import SideBarTitle from "../Common/SideBarTitle";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Title from "../Common/Title";
import SelectBox from "../Common/SelectBox";
import Step from "../Common/Step";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import SidebarLogo from "../Common/SidebarLogo";

const StepOne = ({details, setDetails}) => {

    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, InterestedServices: name});
        fnSetStep(2);
    };

    return (
        <Step>

            <Sidebar>
                <SidebarLogo imageSrc={require("./assets/SonderLogo.png")}/>

                <CenterSidebar>
                    <SideBarTitle>
                        Receive a complementary digital marketing strategy discussion
                    </SideBarTitle>

                    <SideBarText>
                        When you apply for a payment plan you’ll receive a complimentary strategy session with our team.

                        Together we’ll discover new ways in which we can help drive revenue with customised digital
                        marketing solutions that are tightly interlinked to your business goals.
                    </SideBarText>
                </CenterSidebar>


                <BottomImageSideBar imageSrc={require("./assets/StepOneTeamLineup.png")}/>
            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    What services are you interested in?
                </Title>


                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Website only"
                               onSelect={onSelect}
                               image={require("./assets/StepOneWebsite.png")}/>

                    <SelectBox name="Social presence only"
                               onSelect={onSelect}
                               image={require("./assets/StepOneSocial.png")}/>
                </View>

                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Content production only"
                               onSelect={onSelect}
                               image={require("./assets/StepOneConteny.png")}/>

                    <SelectBox name="A combination of the above"
                               onSelect={onSelect}
                               image={require("./assets/StepOneCombo.png")}/>
                </View>


            </Content>

        </Step>
    );
};

export default StepOne;
