import React, {useContext, useState} from 'react';
import {Image, View,} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Sidebar from "../Common/SideBar";
import SideBarTitle from "../Common/SideBarTitle";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Title from "../Common/Title";
import SelectBox from "../Common/SelectBox";
import Step from "../Common/Step";
import {StepContext} from "../Common/Contexts";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import CenterSidebar from "../Common/CenterSidebar";
import * as Animatable from "react-native-animatable";

const StepOne = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, InterestedServices: name});
        fnSetStep(2);
    };

    return (
        <Step>

            <Sidebar>
                <CenterSidebar>
                    <SideBarTitle>
                        Nice!
                    </SideBarTitle>

                    <SideBarText>
                        You’re just a few steps away from a pleasant chat about your digital marketing. And perhaps as a
                        result – skyrocketing your rankings, boosting your website traffic and buying that yacht you’ve
                        always dreamed of.
                    </SideBarText>
                </CenterSidebar>


                <Animatable.View animation="fadeIn" style={{
                    height: 150
                }}>

                    <Image source={require("./assets/StepOneTeamLineup.png")} resizeMode="contain" style={{
                        maxWidth: "100%",
                        height: "100%"
                    }}/>
                </Animatable.View>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    What services are you interested in?
                </Title>


                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Website only"
                               onSelect={onSelect}
                               image={require("./assets/StepOneWebsite.png")}/>

                    <SelectBox name="SEO only"
                               onSelect={onSelect}
                               image={require("./assets/StepOnePhones.png")}/>
                </View>

                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Content production only"
                               onSelect={onSelect}
                               image={require("./assets/StepOneContent.png")}/>

                    <SelectBox name="A combination of the above"
                               onSelect={onSelect}
                               image={require("./assets/StepOneCombo.png")}/>
                </View>


            </Content>

        </Step>
    );
};

export default StepOne;
