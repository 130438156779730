import React, {useState} from 'react';
import {ScrollView, Image, Text, View, TouchableOpacity} from 'react-native';
import useIsMobile from "./useIsMobile";
import * as Animatable from 'react-native-animatable';

const Content = ({children, center}) => {
    const {isMobile} = useIsMobile();

    return (
        <View style={{
            flex: isMobile ? 1 : 1.5,
            justifyContent: center ? "center" : null,
            alignItems: center ? "center" : null,
            paddingHorizontal: isMobile ? 20 : 100,
            backgroundColor: "#F8F8FB"
        }}>
            <Animatable.View animation="slideInRight" style={{flex: 1}} duration={400}>
            {/*<ScrollView style={{flex: 1}}>*/}
                {children}
            {/*</ScrollView>*/}
            </Animatable.View>
        </View>
    );
};

export default Content;
