import React, {useContext, useState} from 'react';
import { Text, TextInput as TextInputNative, View } from 'react-native';
import {ThemingContext} from "./Contexts";

export default function TextInput({label, value, onChangeText, row = false, large = false, type = "text"}) {
    const { Font } = useContext(ThemingContext);

    let container;

    if (type === "text") {
        container = <TextInputNative value={value} onChangeText={onChangeText} multiline={large} style={{
            flex: 1,
            backgroundColor: "white",
            padding: 10,
            fontFamily: Font,
            fontSize: 14,
            shadowColor: "rgba(0,0,0,0.31)",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

        }}/>;

    } else if (type === "date") {
        container = (
            <input type="date"
                   value={value}
                   onChange={(e) => onChangeText(e.target.value)}
                   style={{
                       flex: 1,
                       border: "none",
                       backgroundColor: "white",
                       padding: 10,
                       fontFamily: Font,
                       fontSize: 14,
                       "box-shadow": "rgb(0 0 0 / 8%) 0px 2px 3.84px"
                   }}
            />
        );
    }

    return (
        <View style={{
            marginBottom: 20,
            marginRight: row ? 10 : 0
        }}>
            <Text style={{
                fontFamily: Font,
                fontSize: 14,
                color: "#333333",
                fontWeight: "bold",
                marginBottom: 10
            }}>{label}</Text>

            <View style={{
                height: large ? 80 : null
            }}>
                {container}
            </View>
        </View>
    );
}
