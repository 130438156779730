import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import * as Animatable from 'react-native-animatable';
import useIsMobile from "./useIsMobile";

export default function Render({cta}) {
    const {isMobile, marginTop, maxCTAHeight, maxCTAWidth} = useIsMobile();

    return (
        <View style={{
            flex: 1
        }}>
            <TouchableOpacity onPress={() => window.closeCTA()} style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "#0000008f"
            }}></TouchableOpacity>
            <View style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                alignItems: "center"
            }}>
                <Animatable.View animation="slideInUp" duration={150} style={{
                    width: isMobile ? "100%" : maxCTAWidth,
                    height:isMobile ? "90%" : maxCTAHeight,
                    position: "absolute",
                    marginTop: marginTop,
                    borderRadius: 5,
                    overflow: "hidden"
                }}>
                    {cta}
                </Animatable.View>
            </View>
        </View>
    );
}
