import React, {useContext, useState} from 'react';
import {Image, View} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import OptionSet from "../Common/OptionSet";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import SideBarText from "../Common/SideBarText";
import SideBarSmallText from "../Common/SideBarSmallText";
import CenterSidebar from "../Common/CenterSidebar";
import Quote from "../Common/Quote";
import SidebarLogo from "../Common/SidebarLogo";
import BottomImageSideBar from "../Common/BottomImageSideBar";


const StepThree = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["NewBusiness", "BusinessName"], details)) {
            return;
        }

        fnSetStep(4);
    };

    return (
        <Step>

            <Sidebar>
                <SidebarLogo imageSrc={require("./assets/SonderLogo.png")}/>

                <CenterSidebar>

                    <Quote title={'Creative Director, ShareStory'}
                           quote={'Since website launch we’ve seen our inbound enquiries increase and our new customers perception change for the better.'}
                           logo={require("./assets/StepThreeLogo.png")}
                           name={'Andrew Barnes'}/>

                </CenterSidebar>

                <BottomImageSideBar imageSrc={require("./assets/StepThree.png")}/>
            </Sidebar>

            <Content>

                <ProgressBar currentStep={3}/>

                <Title>
                    We just need a few details about your business
                </Title>


                <OptionSet
                    options={["New", "Existing"]}
                    label="Are you a new or existing business?"
                    onChangeText={(value) => setDetails({...details, NewBusiness: value})}/>

                <TextInput value={details.BusinessName}
                           label="Business name"
                           onChangeText={(value) => setDetails({...details, BusinessName: value})}/>

                <TextInput value={details.BusinessWebSite}
                           label="Website URL (optional)"
                           onChangeText={(value) => setDetails({...details, BusinessWebSite: value})}/>

                <TextInput value={details.SocialMediaAccount}
                           label="Social media account/s (please link)"
                           onChangeText={(value) => setDetails({...details, SocialMediaAccount: value})}/>


                <Button title={"Continue"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepThree;
