import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";
import SidebarLogo from "../Common/SidebarLogo";
import Quote from "../Common/Quote";
import BottomImageSideBar from "../Common/BottomImageSideBar";

const StepFour = ({details, setDetails}) => {
    const {fnComplete} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["MyName", "MyPhoneNumber", "MyEmail"], details)) {
            return;
        }

        fnComplete();
    };

    return (
        <Step>

            <Sidebar>
                <SidebarLogo imageSrc={require("./assets/SonderLogo.png")}/>

                <CenterSidebar>

                    <Quote title={'Owner, Blended Interiors'}
                           quote={'Sonder has done a phenomenal job in developing our website! They have been accommodating in more ways than one and we are so pleased with the ongoing website maintenance service they provide us.'}
                           logo={require("./assets/StepFourLogo.png")}
                           name={'Deanna Squires'}/>

                </CenterSidebar>

                <BottomImageSideBar imageSrc={require("./assets/StepFourPerson.png")}/>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    Please share your personal details so that we can get in touch
                </Title>


                <TextInput value={details.MyName}
                           label="Your name"
                           onChangeText={(value) => setDetails({...details, MyName: value})}/>

                <TextInput value={details.MyPhoneNumber}
                           label="Your phone number"
                           onChangeText={(value) => setDetails({...details, MyPhoneNumber: value})}/>

                <TextInput value={details.MyEmail}
                           label="Your eMail"
                           onChangeText={(value) => setDetails({...details, MyEmail: value})}/>

                <TextInput value={details.Message}
                           label="Leave a message (optional)"
                           large={true}
                           onChangeText={(value) => setDetails({...details, Message: value})}/>


                <Button title={"Submit"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepFour;
