import React, {useContext, useState} from 'react';
import {Image, View,} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Sidebar from "../Common/SideBar";
import SideBarTitle from "../Common/SideBarTitle";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Title from "../Common/Title";
import SelectBox from "../Common/SelectBox";
import Step from "../Common/Step";
import {StepContext} from "../Common/Contexts";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import CenterSidebar from "../Common/CenterSidebar";
import * as Animatable from "react-native-animatable";

const StepOne = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, BestDescribesYou: name});
        fnSetStep(2);
    };

    return (
        <Step>

            <Sidebar>
                <CenterSidebar>
                    <SideBarTitle>
                        Simple Skips
                    </SideBarTitle>

                    <SideBarText>
                        We cater to all individuals and organisations, picking a bin should be easy so we’ve done just that.
                    </SideBarText>
                </CenterSidebar>


                <Animatable.View animation="fadeIn" style={{
                    height: 150
                }}>

                    <Image source={require("./assets/LeftMenu.png")} resizeMode="contain" style={{
                        maxWidth: "100%",
                        height: "100%"
                    }}/>
                </Animatable.View>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    Pick what best describes you
                </Title>


                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Residential"
                               onSelect={onSelect}
                               image={require("./assets/StepOneA.png")}/>
                    <SelectBox name="Commercial"
                               onSelect={onSelect}
                               image={require("./assets/modern-business-center 1.png")}/>

                    <SelectBox name="Government"
                               onSelect={onSelect}
                               image={require("./assets/large-modern-office-building 1.png")}/>


                </View>


            </Content>

        </Step>
    );
};

export default StepOne;
