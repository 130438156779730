import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SelectBox = (
    {
        image,
        name,
        onSelect
    }
) => {
    const theme = useContext(ThemingContext);

    return (
        <TouchableOpacity onPress={() => onSelect(name)} style={{
            backgroundColor: "white",
            borderRadius: 5,
            margin: 10,
            flex: 1,
            alignItems:'flex-start',
            overflow: "hidden"
        }}>
            <Image source={image} style={{
                height: 80,
                width: "100%"
            }} resizeMode="contain"/>

            <Text style={{
                fontFamily: theme.Font,
                padding: 10,
                width:'100%',
                textAlign: "center",
                fontSize: 14,
                color: "#333333",
                fontWeight: "bold"
            }}>{name}</Text>
        </TouchableOpacity>
    )
}

export default SelectBox;
