import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const SideBarTitle = ({children, textColor = "white"}) => {
    const { Font } = useContext(ThemingContext);

    return (
        <Text style={{
            fontFamily: Font,
            fontSize: 25,
            color: textColor,
            marginBottom: 20,
            fontWeight: "bold"
        }}>
            {children}
        </Text>
    )
};

export default SideBarTitle;
