import React from 'react';
import { View } from 'react-native';
import {parse} from "query-string";

import Render from "./Common/Render";
import SonderCTA from "./SonderCTA/SonderCTA";
import SonderPaymentPLanCTA from "./SonderPaymentPlanCTA/SonderPyamentPlanCTA";
import LeMansCTA from "./LeMans/LeMansCTA";

import { render } from 'react-dom';
import SimpleSkipsCTA from "./SimpleSkips/SimpleSkips";

const App = (props) => {
    let id = "";

    if (props && props.cta) {
        id = props.cta;

    } else {
        let qs =  parse(location.search);

        id = qs.id;
    }



    return (
        <>
            {id == "simpleskips" && <Render cta={<SimpleSkipsCTA />} url={''}/>}

            {id === "sonder" && <Render cta={<SonderCTA/>} url={"https://sonder.com.au"}/>}

            {id === "sonderpaymentplans" && <Render cta={<SonderPaymentPLanCTA/>} url={"https://sonder.com.au/payment-plans/"}/>}

            {id === "lemans" && <Render cta={<LeMansCTA/>} url={"https://lemansmotors.com.au"}/>}
        </>
    );
}

window.closeCTA = () => document.getElementById('root').remove();

window.renderCTA = (id) => {
    const div = document.createElement("div");
    div.id = "root";
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.position = "fixed";
    div.style.left = "0";
    div.style.top = "0";
    div.style.display = "flex";
    div.style["z-index"] = "99999";

    document.body.appendChild(div);

    render(<App cta={id} />, document.getElementById('root'));
}

export default App;
