import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";

const CompleteText = ({children, center = false}) => {
    const theme = useContext(ThemingContext);

    return (
        <Text style={{
            fontFamily: theme.Font,
            fontSize: 16,
            color: "#333333",
            marginTop: 15,
            fontWeight: "bold",
            textAlign: center ? "center" : "left"
        }}>{children}</Text>
    );
};

export default CompleteText;
