import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableHighlight} from 'react-native';
import {StepContext, ThemingContext} from "./Contexts";

const ProgressBarItem = ({number, active, current}) => {
    const theme = useContext(ThemingContext);
    const {fnSetStep} = useContext(StepContext);

    return (
        <TouchableHighlight onPress={() => fnSetStep(number)} disabled={!current} style={{
            backgroundColor: active ? theme.ProgressBar : null,
            borderRadius: 5,
            overflow: "hidden",
            width: 34,
            height: 38,
            opacity: current ? 1 : .5,
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Text style={{
                fontFamily: theme.Font,
                fontSize: 22,
                color: active ? "white" : "#333333"
            }}>
                {number}
            </Text>
        </TouchableHighlight>
    )
}


const ProgressBar = () => {
    const {Total, Current} = useContext(StepContext);

    const createSpacer = (key) => <View key={"spacer_" + key} style={{
        height: 5,
        width: 40,
        borderTopWidth: 5,
        borderTopColor: "#CBC8C8",
        borderTopStyle: "dotted"
    }}/>;

    let progress = [];

    for (let i = 0; i < Total; i++) {
        progress.push(<ProgressBarItem key={i} number={i + 1} current={i <= Current - 1} active={Current === i + 1}/>)

        if (i < Total - 1) {
            progress.push(createSpacer(i));
        }
    }

    return (
        <View style={{
            alignItems: "center",
            marginTop: 20,
            marginBottom: 10
        }}>
            <View style={{
                flexDirection: "row",
                alignItems: "center"
            }}>
                {progress}
            </View>
        </View>
    );
};

export default ProgressBar;
