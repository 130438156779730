import React, {createContext} from 'react';

export const ThemingContext = createContext({
    Background: {
        Gradient: "blue"
    },
    Font: "Arial",
    SelectedOption: "pink",
    Button: "red",
    ProgressBar: "red"
});

export const StepContext = createContext({
    Total: 0,
    Current: 0,
    fnSetSet: null,
    fnComplete: null
});

