import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import SelectRow from "../Common/SelectRow";
import {StepContext} from "../Common/Contexts";
import CenterSidebar from "../Common/CenterSidebar";

const StepTwo = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, BestDescribesMarketing: name});
        fnSetStep(3);
    };

    return (
        <Step>
            <Sidebar center={true}>

                <CenterSidebar>
                    <SideBarText>
                        Trusted by 153 Brisbane businesses using digital marketing to their advantage
                    </SideBarText>


                    <Image source={require("./assets/StepTwoLogos.png")} resizeMode="contain" style={{
                        width: "100%",
                        height: 200
                    }}/>
                </CenterSidebar>

            </Sidebar>


            <Content>
                <ProgressBar/>

                <Title>
                    Which best describes your marketing?
                </Title>


                <SelectRow name="I’m completely new to digital marketing" onSelect={onSelect}/>

                <SelectRow name="I’ve got a great strategy and want to build on it" onSelect={onSelect}/>

                <SelectRow name="My strategy isn’t working and I want to fix it" onSelect={onSelect}/>

                <SelectRow name="I want to completely overhaul my marketing" onSelect={onSelect}/>

                <SelectRow name="I’m just looking to get a quote from you" onSelect={onSelect}/>


            </Content>
        </Step>
    );
};

export default StepTwo;
