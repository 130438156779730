import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import SideBarSmallText from "../Common/SideBarSmallText";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import Quote from "../Common/Quote";
import CenterSidebar from "../Common/CenterSidebar";
import BottomImageSideBar from "../Common/BottomImageSideBar";

const StepFour = ({details, setDetails}) => {
    const {fnComplete} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["MyName", "MyPhoneNumber", "MyEmail"], details)) {
            return;
        }

        fnComplete();
    };

    return (
        <Step>

            <Sidebar>


                <CenterSidebar>

                    <Quote title={'Marcus Newton'}
                           quote={'We engaged Sonder in 2017 to take care of our monthly marketing and website SEO.\n\nThey are incredibly efficient, and are transparent and honest throughout their entire process. We have experienced incredible year-on-year growth since working with them and we now consider Sonder to be a core part of our business.'}
                           logo={require("./assets/StepFourLeMans.png")}
                           name={'General Manager, LeMans'}/>

                </CenterSidebar>

                <BottomImageSideBar imageSrc={require("./assets/StepFourMark.png")}/>
            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    Please share your personal details so that we can get in touch
                </Title>


                <TextInput value={details.MyName}
                           label="Your name"
                           onChangeText={(value) => setDetails({...details, MyName: value})}/>

                <TextInput value={details.MyPhoneNumber}
                           label="Your phone number"
                           onChangeText={(value) => setDetails({...details, MyPhoneNumber: value})}/>

                <TextInput value={details.MyEmail}
                           label="Your eMail"
                           onChangeText={(value) => setDetails({...details, MyEmail: value})}/>

                <TextInput value={details.Message}
                           label="Leave a message (optional)"
                           large={true}
                           onChangeText={(value) => setDetails({...details, Message: value})}/>


                <Button title={"Submit"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepFour;
