import React, {useContext, useState} from 'react';
import {Image, View,} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Sidebar from "../Common/SideBar";
import SideBarTitle from "../Common/SideBarTitle";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Title from "../Common/Title";
import SelectBox from "../Common/SelectBox";
import Step from "../Common/Step";
import {StepContext} from "../Common/Contexts";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import CenterSidebar from "../Common/CenterSidebar";
import * as Animatable from "react-native-animatable";
import SidebarLogo from "../Common/SidebarLogo";

const StepTwo = ({details, setDetails}) => {
    const {fnSetStep} = useContext(StepContext);

    const onSelect = (name) => {
        setDetails({...details, BookInFor: name});
        fnSetStep(3);
    };

    return (
        <Step>

            <Sidebar style={{
                backgroundColor: "#B2FCE4"
            }}>


                <Image source={require("./assets/step2.png")} resizeMode="contain" style={{
                    width: 150,
                    height: 50,
                    marginVertical: 30
                }}/>


                <SideBarTitle textColor={"#000000"}>
                    Afterpay available at all of our locations
                </SideBarTitle>

                <SideBarText textColor={"#000000"}>
                    We’re excited to be among the first Afterpay mechanics in Brisbane, letting our customers spread out
                    the cost of servicing or repairs over time in easy to manage instalments.
                </SideBarText>


            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    What would you like to book in for?
                </Title>


                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="General car service"
                               onSelect={onSelect}
                               image={require("./assets/step2car.png")}/>

                    <SelectBox name="Car repairs"
                               onSelect={onSelect}
                               image={require("./assets/step2repair.png")}/>
                </View>

                <View style={{
                    flexDirection: "row",
                }}>
                    <SelectBox name="Roadworthy Certificate"
                               onSelect={onSelect}
                               image={require("./assets/step2roadworthy.png")}/>

                    <SelectBox name="Something else"
                               onSelect={onSelect}
                               image={require("./assets/step2somethingelse.png")}/>
                </View>


            </Content>

        </Step>
    );
};

export default StepTwo;
