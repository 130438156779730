import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import ProgressBar from "../Common/ProgressBar";
import Title from "../Common/Title";
import Sidebar from "../Common/SideBar";
import SideBarText from "../Common/SideBarText";
import Content from "../Common/Content";
import Step from "../Common/Step";
import TextInput from "../Common/TextInput";
import Button from "../Common/Button";
import SideBarSmallText from "../Common/SideBarSmallText";
import {ValidateMe} from "../Common/ValidateMe";
import {StepContext} from "../Common/Contexts";
import Quote from "../Common/Quote";
import CenterSidebar from "../Common/CenterSidebar";
import BottomImageSideBar from "../Common/BottomImageSideBar";
import * as Animatable from "react-native-animatable";

const StepFour = ({details, setDetails}) => {
    const {fnComplete} = useContext(StepContext);

    const onContinue = () => {
        if (!ValidateMe(["Name", "ContactName", "Address", "Suburb", "Postcode", "PhoneNumber", "DropOffDate", "PickUpDate"], details)) {
            return;
        }

        fnComplete();
    };

    return (
        <Step>

            <Sidebar>
                <CenterSidebar>


                    <SideBarText>
                        We Deliver to most places in Brisbane & Sunshine Coast! Enter your address to see if we deliver to you!
                    </SideBarText> </CenterSidebar>

                <Animatable.View animation="fadeIn" style={{
                    height: 150
                }}>

                    <Image source={require("./assets/map 2.png")} resizeMode="contain" style={{
                        maxWidth: "100%",
                        height: "100%"
                    }}/>
                </Animatable.View>

            </Sidebar>

            <Content>

                <ProgressBar/>

                <Title>
                    Please share your personal details so that we can get in touch
                </Title>

                <View style={{flexDirection: 'row', width: '100%'}}>

                    <TextInput value={details.Name}
                               label="Name / Company Name"
                               row={true}
                               onChangeText={(value) => setDetails({...details, Name: value})}/>

                    <TextInput value={details.ContactName}
                               label="Contact Name"
                               onChangeText={(value) => setDetails({...details, ContactName: value})}/>

                </View>



                <View style={{flexDirection: 'row', width: '100%'}}>

                    <TextInput value={details.Email}
                               label="Email"
                               row={true}
                               onChangeText={(value) => setDetails({...details, Email: value})}/>

                </View>

                <View style={{flexDirection: 'row', width: '100%'}}>

                    <TextInput value={details.Address}
                               label="Address"
                               row={true}
                               onChangeText={(value) => setDetails({...details, Address: value})}/>

                    <TextInput value={details.Suburb}
                               label="Suburb"
                               row={true}
                               onChangeText={(value) => setDetails({...details, Suburb: value})}/>


                </View>

                <View style={{flexDirection: 'row', width: '100%'}}>


                    <TextInput value={details.Postcode}
                               label="Postcode"
                               row={true}
                               onChangeText={(value) => setDetails({...details, Postcode: value})}/>


                    <TextInput value={details.PhoneNumber}
                               label="Phone Number"
                               onChangeText={(value) => setDetails({...details, PhoneNumber: value})}/>


                </View>

                <View style={{flexDirection: 'row', width: '100%'}}>


                    <TextInput value={details.DropOffDate}
                               label="Drop Off Date"
                               type={"date"}
                               row={true}
                               onChangeText={(value) => setDetails({...details, DropOffDate: value})}/>

                    <TextInput value={details.PickUpDate}
                               label="Pick Up Date"
                               type={"date"}
                               onChangeText={(value) => setDetails({...details, PickUpDate: value})}/>




                </View>


                <Button title={"Submit"} onPress={onContinue}/>


            </Content>

        </Step>
    );
};

export default StepFour;
