import React, {useContext, useState} from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import {ThemingContext} from "./Contexts";
import * as Animatable from 'react-native-animatable';

const CompleteTitle = ({children, center = false}) => {
    const theme = useContext(ThemingContext);

    return (
        <Animatable.Text animation="zoomIn" duration={500} style={{
            fontFamily: theme.Font,
            fontSize: 35,
            color: "#333333",
            marginTop: 50,
            fontWeight: "bold",
            textAlign: center ? "center" : "left"
        }}>{children}</Animatable.Text>
    );
};

export default CompleteTitle;
